import { create } from "zustand";

export const MOBILE_SCREEN_WIDTH = 768;

interface ScreenStore {
  isMobileScreen: boolean;
  setIsMobileScreen: (isMobile: boolean) => void;
}

export const useScreenStore = create<ScreenStore>((set) => ({
  isMobileScreen: false,
  setIsMobileScreen: (isMobile) => set({ isMobileScreen: isMobile }),
}));
