import HENGE from "@henge-inc/global-types";

export const GAIA_MEMBER_ROLE = {
  GUEST: "GUEST",
  MEMBER: "MEMBER",
  OWNER: "OWNER",
} as const;
export type GaiaMemberRoleType = HENGE.ValuesTypeFromObject<
  typeof GAIA_MEMBER_ROLE
>;

export const ROSETTA_CODE = {
  DOCUMENT: "DOCUMENT",
  CAMERA: "CAMERA",
  LIGHT: "LIGHT",
  IDEATION: "IDEATION",
  MATERIAL: "MATERIAL",
} as const;
export type RosettaCodeType = HENGE.ValuesTypeFromObject<typeof ROSETTA_CODE>;

export const ROSETTA_RELEASE_STATUS = {
  INTERNAL_REVIEW: "INTERNAL_REVIEW",
  COMING_SOON: "COMING_SOON",
  RELEASED: "RELEASED",
  NOT_IN_SUPPORT: "NOT_IN_SUPPORT",
} as const;
export type RosettaReleaseStatusType = HENGE.ValuesTypeFromObject<
  typeof ROSETTA_RELEASE_STATUS
>;

export const VAULT_MEMBER_ROLE = {
  OWNER: "OWNER",
  EDITOR: "EDITOR",
  VIEWER: "VIEWER",
} as const;
export type VaultMemberRoleType = HENGE.ValuesTypeFromObject<
  typeof VAULT_MEMBER_ROLE
>;

export const VAULT_MEMBER_STATUS = {
  JOIN: "JOIN",
  REQ_JOIN: "REQ_JOIN",
  REJECT_REQ_JOIN: "REJECT_REQ_JOIN",
  BLOCK_REQ_JOIN: "BLOCK_REQ_JOIN",
  REQ_INVITE: "REQ_INVITE",
  REJECT_REQ_INVITE: "REJECT_REQ_INVITE",
  BLOCK_REQ_INVITE: "BLOCK_REQ_INVITE",
  LEAVE: "LEAVE",
} as const;
export type VaultMemberStatusType = HENGE.ValuesTypeFromObject<
  typeof VAULT_MEMBER_STATUS
>;

export const VAULT_OBJECT_TYPE = {
  OBJECT: "OBJECT",
  MATERIAL: "MATERIAL",
  PBR: "PBR",
} as const;
export type VaultObjectType = HENGE.ValuesTypeFromObject<
  typeof VAULT_OBJECT_TYPE
>;

export const VAULT_OBJECT_TYPE_FILTER = {
  All: "All",
  Geometry: "Geometry",
  Material: "Material",
  Physics: "Physics",
} as const;
export type VaultObjectTypeFilter = HENGE.ValuesTypeFromObject<
  typeof VAULT_OBJECT_TYPE_FILTER
>;

export const VAULT_TYPE = {
  MY_PAGE: "MY_PAGE",
  ORGANIZATION: "ORGANIZATION",
} as const;
export type VaultType = HENGE.ValuesTypeFromObject<typeof VAULT_TYPE>;

export const VAULT_ACCESS = {
  PUBLIC: "PUBLIC",
  PRIVATE: "PRIVATE",
} as const;
export type VaultAccess = HENGE.ValuesTypeFromObject<typeof VAULT_ACCESS>;

export const VAULT_UPLOAD_POLICY = {
  ANYONE: "ANYONE",
  ONLY_ME: "ONLY_ME",
} as const;
export type VaultUploadPolicy = HENGE.ValuesTypeFromObject<
  typeof VAULT_UPLOAD_POLICY
>;

export const SKETCHFAB_MIGRATION_INSPECTION = {
  NEVER: "NEVER",
  IN_PROGRESS: "IN_PROGRESS",
  FINISHED: "FINISHED",
} as const;
export type SketchfabMigrationInspection = HENGE.ValuesTypeFromObject<
  typeof SKETCHFAB_MIGRATION_INSPECTION
>;

export const SKETCHFAB_MIGRATION_TASK_STATUS = {
  INITIALIZED: "INITIALIZED",
  IN_PROGRESS: "IN_PROGRESS",
  FINISHED: "FINISHED",
} as const;
export type SketchfabMigrationTaskStatus = HENGE.ValuesTypeFromObject<
  typeof SKETCHFAB_MIGRATION_TASK_STATUS
>;

export const LICENSE = {
  CC_BY: "CC_BY",
  CC_BY_SA: "CC_BY_SA",
  CC_BY_ND: "CC_BY_ND",
  CC_BY_NC: "CC_BY_NC",
  CC_BY_NC_SA: "CC_BY_NC_SA",
  CC_BY_NC_ND: "CC_BY_NC_ND",
  CC0: "CC0",
} as const;
export type License = HENGE.ValuesTypeFromObject<typeof LICENSE>;

export const LICENSE_LABEL = {
  CC_BY: "CC BY",
  CC_BY_SA: "CC BY-SA",
  CC_BY_ND: "CC BY-ND",
  CC_BY_NC: "CC BY-NC",
  CC_BY_NC_SA: "CC BY-NC-SA",
  CC_BY_NC_ND: "CC BY-NC-ND",
  CC0: "CC0",
};

export const LICENSE_DETAIL_INFO = {
  CC_BY: "Attribution required. Commercial use is allowed.",
  CC_BY_SA:
    "Attribution required. Derivative works must be shared under the same license. Commercial use is allowed.",
  CC_BY_ND:
    "Attribution required. No derivative works may be distributed. Commercial use is allowed.",
  CC_BY_NC: "Attribution required. No commercial use.",
  CC_BY_NC_SA:
    "Attribution required. No commercial use. Derivative works must be shared under the same license.",
  CC_BY_NC_ND:
    "Attribution required. No commercial use. No derivative works may be distributed.",
  CC0: "Attribution is not mandatory. Commercial use is allowed.",
} as const;
