import React from "react";

export const CURSOR_ENUM = {
  default: "default",
  pointer: "pointer",
  grab: "grab",
  grabbing: "grabbing",
  move: "move",
  ns_resize: "ns-resize",
  zoom_in: "zoom-in",
  zoom_out: "zoom-out",
  wait: "wait",
  help: "help",
};
export type CursorType = (typeof CURSOR_ENUM)[keyof typeof CURSOR_ENUM];

// export function changeCursorType(cursor: CursorType): void {
//   if (!document.body.classList.contains(cursor)) {
//     document.body.classList.remove(...Object.values(CURSOR_ENUM));
//     document.body.classList.add(cursor);
//   }
// }

export function changeCanvasCursorType(
  canvas: HTMLCanvasElement,
  cursor: CursorType,
): void {
  canvas.style.cursor = cursor;
}

export function copyTextToClipboard(text: string) {
  // 1. navigator.clipboard API 방식 (fallback)
  return navigator.clipboard
    .writeText(text)
    .then((value) => {
      return value;
    })
    .catch(() => {
      // 2. document.execCommand() 방식
      try {
        // 임시 textarea 엘리먼트 생성
        const textarea = document.createElement("textarea");
        textarea.value = text;

        // 화면에서 보이지 않게 설정
        textarea.style.position = "fixed";
        textarea.style.left = "-9999px";
        textarea.style.top = "0";

        document.body.appendChild(textarea);
        textarea.focus();
        textarea.select();

        // 복사 실행
        const successful = document.execCommand("copy");
        document.body.removeChild(textarea);

        if (successful) {
          return Promise.resolve();
        } else {
          return Promise.reject();
        }
      } catch (err) {
        console.warn("execCommand 방식 실패:", err);
        return Promise.reject(err);
      }
    });
}

export function calculateTextColor(bgColor: string) {
  const r = parseInt(bgColor.slice(1, 3), 16);
  const g = parseInt(bgColor.slice(3, 5), 16);
  const b = parseInt(bgColor.slice(5, 7), 16);
  const brightness = (r * 299 + g * 587 + b * 114) / 1000;

  return brightness > 128 ? "#000000" : "#FFFFFF";
}

export interface HengeMetaTagProps {
  title: string;
  description: string;
  keywords: string[];
  image: string;
}

export const DEFAULT_META_TAG_PROPS: HengeMetaTagProps = {
  title: "Henge - Engine for Design",
  description: "All-in-one workspace for building 3D projects",
  keywords: [],
  image: "https://cdn.henge.io/assets/images/og/QR-Home.png",
};

export function createMetaTags(
  url: string,
  { title, description, keywords, image }: HengeMetaTagProps,
) {
  return (
    <>
      <meta charSet="utf-8" />
      <meta httpEquiv={"Cache-Control"} content={"no-cache"} />
      <meta httpEquiv={"Expires"} content={"0"} />
      <meta httpEquiv={"Pragma"} content={"no-cache"} />

      <meta
        name={"keywords"}
        content={["Henge", "henge", "3D", "3d", ...keywords].join(", ")}
      />

      <meta name={"title"} content={title} />
      <meta name={"description"} content={description} />

      <meta property={"og:type"} content={"website"} />
      <meta property={"og:url"} content={url} />

      <meta property={"og:title"} content={title} />
      <meta property={"og:description"} content={description} />
      <meta property={"og:image"} content={image} />
    </>
  );
}
