import {
  useMember,
  useMemberCursorStore,
  useMemberNextAuthSession,
  useMemberStoreActions,
} from "@/stores/zustand/member/useMemberStore";
import {
  useCursorStoreActions,
  useInitCursorStore,
} from "@/stores/zustand/useCursorStore";
import { useGlobalActions } from "@/stores/zustand/useGlobalStore";
import HUI from "@henge-inc/ui/v1_2";
import { useSession } from "next-auth/react";
import { useEffect } from "react";
import { GA4_CUSTOM_TRIGGER } from "@/plugins/google-tag-manager/google-tag-manager-constants";

export const GloballyNextAuthSession = () => {
  const nextAuthSession = useSession();

  const member = useMember();
  const {
    updateMember,
    updateNextAuthSession,
    loadingAuthTokens,
    authenticateAuthTokens,
    unauthenticateAuthTokens,
    refreshLibraryList,
    refreshNotificationList,
  } = useMemberStoreActions();

  const memberNextAuthSession = useMemberNextAuthSession();

  const cursorStore = useMemberCursorStore();
  const initCursorStore = useInitCursorStore(cursorStore);
  const { changeColour } = useCursorStoreActions(cursorStore);

  const { initSocket } = useGlobalActions();

  useEffect(() => {
    updateNextAuthSession(nextAuthSession);
  }, [nextAuthSession]);

  useEffect(() => {
    if (
      memberNextAuthSession.status === "authenticated" &&
      memberNextAuthSession.data?.user?.member
    ) {
      updateMember(memberNextAuthSession.data.user.member);
      window.dataLayer.push(
        GA4_CUSTOM_TRIGGER.Config.Member(
          memberNextAuthSession.data.user.member.metadata.uid,
        ),
      );
    }
  }, [memberNextAuthSession]);

  useEffect(() => {
    if (
      memberNextAuthSession.status === "authenticated" &&
      memberNextAuthSession.data?.user?.authTokens
    ) {
      authenticateAuthTokens(memberNextAuthSession.data.user.authTokens);

      refreshLibraryList(memberNextAuthSession.data.user.authTokens);
      refreshNotificationList(memberNextAuthSession.data.user.authTokens);
    } else if (memberNextAuthSession.status === "unauthenticated") {
      unauthenticateAuthTokens();
    } else {
      loadingAuthTokens();
    }
  }, [memberNextAuthSession]);

  useEffect(() => {
    if (!member) return;

    initCursorStore({
      abbrName: HUI.abbreviateName(
        member.metadata.familyName,
        member.metadata.givenName,
      ),
      personalColour: member.metadata.personalColour,
    });
  }, [member]);

  useEffect(() => {
    if (!member?.metadata?.personalColour) return;

    changeColour(member.metadata.personalColour);
  }, [member?.metadata?.personalColour]);

  useEffect(() => {
    const mousemoveHandler = (evt: MouseEvent) => {
      cursorStore.getState().cursorStoreActions.updateMouse(evt);
    };

    window.addEventListener("mousemove", mousemoveHandler);
    window.addEventListener("dragover", mousemoveHandler);

    return () => {
      window.removeEventListener("mousemove", mousemoveHandler);
      window.removeEventListener("dragover", mousemoveHandler);
    };
  }, [nextAuthSession]);

  useEffect(() => {
    if (
      memberNextAuthSession.data?.user?.member &&
      memberNextAuthSession.data?.user?.authTokens
    ) {
      initSocket(
        memberNextAuthSession.data.user.member,
        memberNextAuthSession.data.user.authTokens,
      );
    }
  }, [memberNextAuthSession]);

  return <></>;
};
